/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M.667 8a7.333 7.333 0 1 1 14.666 0A7.333 7.333 0 0 1 .667 8M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2M5.643 5.643c.26-.26.683-.26.943 0L8 7.057l1.414-1.414a.667.667 0 1 1 .943.943L8.943 8l1.414 1.414a.667.667 0 1 1-.943.943L8 8.943l-1.414 1.414a.667.667 0 0 1-.943-.943L7.057 8 5.643 6.586a.667.667 0 0 1 0-.943"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgCloseCircle: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-close-circle", props.className)} />
));

export default SvgCloseCircle;
