/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 15 14">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8.838 1.629a.583.583 0 0 1 .825 0l3.5 3.5a.583.583 0 0 1-.413.996H2.25a.583.583 0 1 1 0-1.167h9.092L8.838 2.454a.583.583 0 0 1 0-.825M1.944 8.235a.58.58 0 0 1 .54-.36h10.5a.583.583 0 1 1 0 1.166H3.89l2.505 2.505a.583.583 0 0 1-.825.825l-3.5-3.5a.58.58 0 0 1-.127-.636"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgSwitch: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-switch", props.className)} />
));

export default SvgSwitch;
