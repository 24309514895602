/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 28 28">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8.75 2.333c0-.645.522-1.167 1.167-1.167h8.166c.645 0 1.167.522 1.167 1.167v.583h2.917A2.333 2.333 0 0 1 24.5 5.249V24.5a2.333 2.333 0 0 1-2.333 2.334H5.833A2.333 2.333 0 0 1 3.5 24.499V5.25a2.333 2.333 0 0 1 2.333-2.333H8.75zm2.333 2.333h5.834V3.499h-5.834zm8.167 1.167c0 .644-.522 1.166-1.167 1.166H9.917A1.167 1.167 0 0 1 8.75 5.833v-.584H5.833V24.5h16.334V5.25H19.25zm-2.675 4.425a1.167 1.167 0 0 1 0 1.65L13.9 14.583h3.019a1.167 1.167 0 0 1 .825 1.992l-4.669 4.667a1.167 1.167 0 1 1-1.65-1.65l2.677-2.675h-3.019a1.167 1.167 0 0 1-.825-1.992l4.667-4.667a1.167 1.167 0 0 1 1.65 0"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgClipboard: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-clipboard", props.className)} />
));

export default SvgClipboard;
