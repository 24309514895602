import { AxiosResponse } from "axios";

import * as auth from "./auth";
import api from "./apiClient";
import { ApiInfos } from "./types";
import { downloadFile } from "../../helpers";

export type ApiResponse = Record<string, any>;

export interface RequestError {
    data?: any;
    headers?: any;
    message: string;
    status: number;
}

export interface PaginationQuery {
    page?: number;
    pageSize?: number;
    sort?: string;
    sortOrder?: string;
}

export interface SearchQuery {
    search?: string;
}

export type SearchPaginationQuery = PaginationQuery & SearchQuery;

export default {
    auth,
};

export const infos = async () => await api.get<ApiInfos>("/").then((response) => response?.data);

export const downloadResponseAsJSON = (response: AxiosResponse<Blob>) => {
    const result = response.data as unknown as BlobPart;
    const filename = response.headers["content-disposition"]?.split("=")[1];
    const blob = new Blob([result], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);

    downloadFile(url, filename);

    window.setTimeout(() => {
        window.URL.revokeObjectURL(url);
    }, 35000);
};
