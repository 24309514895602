/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 28 28">
        <path
            fill="currentColor"
            d="M9.917 8.167c0-.645.522-1.167 1.166-1.167h5.834a1.167 1.167 0 1 1 0 2.333h-5.834a1.167 1.167 0 0 1-1.166-1.166M9.333 11.667a1.167 1.167 0 1 0 0 2.333h9.334a1.167 1.167 0 1 0 0-2.333z"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5.25 2.333c-.553 0-1.03.388-1.142.93L1.196 17.237a1.2 1.2 0 0 0-.03.287v7.558c0 .645.523 1.167 1.167 1.167h23.334c.644 0 1.166-.522 1.166-1.167v-7.558a1.2 1.2 0 0 0-.03-.287l-2.91-13.976a1.17 1.17 0 0 0-1.143-.929zm18.982 14-2.43-11.666H6.198l-2.43 11.666h4.928c.514 0 .968.337 1.117.829l.81 2.671h6.753l.81-2.671a1.17 1.17 0 0 1 1.116-.829zM3.5 23.917v-5.25h4.332l.81 2.671c.148.492.602.829 1.116.829h8.485c.514 0 .967-.337 1.116-.829l.81-2.671H24.5v5.25z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgInbox: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-inbox", props.className)} />
));

export default SvgInbox;
