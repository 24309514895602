import api from "./apiClient";
import { SearchPaginationQuery } from ".";
import { Store, ListResponse } from "./types";

// Controller Interface
export interface StoreCreatePayload
    extends Pick<Store, "name" | "reference" | "address" | "numberOfDocks" | "secondaryNumberOfDocks"> {
    secondaryAddress?: Store["secondaryAddress"];
}

export interface StoreUpdatePayload extends StoreCreatePayload {
    id?: Store["id"];
}

export type StoreIdPayload = Store["id"];

export type StoreListPayload = SearchPaginationQuery;
export type StoreListResponse = ListResponse<Store>;

// Routes
export const list = async (payload?: StoreListPayload) => {
    return await api.get<StoreListResponse>(`/stores`, { params: payload }).then((response) => response?.data);
};

export const create = async (payload: StoreCreatePayload) => {
    return await api.post(`/stores`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: StoreUpdatePayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.put<Store>(`/stores/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: StoreIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.get<Store>(`/stores/${id}`).then((response) => response?.data);
};

export const remove = async (id?: StoreIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.delete<undefined>(`/stores/${id}`).then((response) => response?.data);
};
