/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40">
        <path
            fill="currentColor"
            d="M25.846 21.669q.24 0 .466.003l.855.014.763.023.681.035c1.607.105 2.537.337 3.506.83a8.33 8.33 0 0 1 3.641 3.642l.082.164.148.322.13.324.058.164.104.34.046.178.08.376q.02.099.036.2l.061.43.05.478.038.531.03.595.028 1.03.016 1.67.002 1.982c0 .92-.747 1.666-1.667 1.666H5c-.92 0-1.667-.746-1.667-1.667v-2.02l.006-.958.014-.856.023-.762.035-.681c.104-1.607.337-2.537.83-3.506a8.33 8.33 0 0 1 3.642-3.641c1.033-.527 2.023-.756 3.836-.85l.72-.029.809-.018.906-.01zm-.057 3.333H14.214l-1.163.014-.955.03-.537.03-.466.04-.405.05-.351.062a4 4 0 0 0-.58.16l-.245.1-.115.057a5 5 0 0 0-2.185 2.185 3 3 0 0 0-.108.235l-.094.257a4 4 0 0 0-.115.448l-.062.351-.05.405-.04.466-.042.833-.025 1.02-.01 1.588h26.666l-.005-1.061-.01-.747-.025-.948-.025-.53-.033-.46-.04-.396-.05-.344-.06-.3-.034-.135-.077-.25-.088-.227-.097-.205a5 5 0 0 0-2.185-2.185 3 3 0 0 0-.235-.108l-.258-.094a4 4 0 0 0-.447-.115l-.351-.062-.405-.05-.466-.04-.833-.042-1.02-.024zm-5.79-21.669a7.5 7.5 0 1 1 .001 15 7.5 7.5 0 0 1 0-15m0 3.333A4.167 4.167 0 1 0 20 15a4.167 4.167 0 0 0 0-8.334"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgPeople: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-people", props.className)} />
));

export default SvgPeople;
