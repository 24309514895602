/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <g fill="currentColor">
            <path d="M4 2a2 2 0 0 0-2 2v4a1 1 0 0 0 2 0V4h4a1 1 0 0 0 0-2zM4 16a1 1 0 1 0-2 0v4a2 2 0 0 0 2 2h4a1 1 0 1 0 0-2H4zM21 15a1 1 0 0 1 1 1v4a2 2 0 0 1-2 2h-4a1 1 0 1 1 0-2h4v-4a1 1 0 0 1 1-1M16 2a1 1 0 1 0 0 2h4v4a1 1 0 1 0 2 0V4a2 2 0 0 0-2-2zM13 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
            <path
                fillRule="evenodd"
                d="M13 7.612V6.5a1 1 0 1 0-2 0v1.112A4.51 4.51 0 0 0 7.612 11H6.5a1 1 0 1 0 0 2h1.112A4.51 4.51 0 0 0 11 16.389V17.5a1 1 0 1 0 2 0v-1.111A4.51 4.51 0 0 0 16.389 13H17.5a1 1 0 1 0 0-2h-1.111A4.51 4.51 0 0 0 13 7.612M12 14.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5"
                clipRule="evenodd"
            />
        </g>
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgFocusOne: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-focus-one", props.className)} />
));

export default SvgFocusOne;
