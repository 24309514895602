/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M7.083.833c.46 0 .834.373.834.834V2.5h4.166v-.833a.833.833 0 0 1 1.667 0V2.5h3.75c.92 0 1.667.746 1.667 1.667V17.5c0 .92-.747 1.667-1.667 1.667h-15c-.92 0-1.667-.747-1.667-1.667V4.167c0-.92.747-1.667 1.667-1.667h3.75v-.833c0-.46.373-.834.833-.834M6.25 4.167H2.5V7.5h15V4.167h-3.75V5a.833.833 0 1 1-1.667 0v-.833H7.917V5A.833.833 0 0 1 6.25 5zm11.25 5h-3.75V12.5h3.75zm0 5h-3.75V17.5h3.75zM12.083 17.5v-3.333H7.917V17.5zm-5.833 0v-3.333H2.5V17.5zm-3.75-5h3.75V9.167H2.5zm5.417-3.333V12.5h4.166V9.167z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgCalendar: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-calendar", props.className)} />
));

export default SvgCalendar;
