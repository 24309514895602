import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import {
    create,
    details,
    list,
    CarrierCreatePayload,
    CarrierIdPayload,
    CarrierListPayload,
    CarrierListResponse,
    CarrierUpdatePayload,
    remove,
    update,
} from "./api/carriers";
import { Carrier } from "./api/types";

export const carriersKeys = {
    all: ["carriers"],
    lists: () => [...carriersKeys.all, "list"],
    list: (params?: CarrierListPayload) => [...carriersKeys.lists(), params],
    details: () => [...carriersKeys.all, "details"],
    detail: (id?: CarrierIdPayload) => [...carriersKeys.details(), id],
};

export const useCarrierList = <TData = CarrierListResponse>(
    params?: CarrierListPayload,
    options?: UseQueryOptions<CarrierListResponse, AxiosError, TData>
) => {
    return useQuery<CarrierListResponse, AxiosError, TData>(carriersKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
        ...options,
    });
};

export const useCarrierDetails = <TData = Carrier>(
    id?: CarrierIdPayload,
    options?: UseQueryOptions<Carrier, AxiosError, TData>
) => {
    return useQuery<Carrier, AxiosError, TData>(carriersKeys.detail(id), async () => await details(id), options);
};

export const useCarrierCreate = (options?: UseMutationOptions<Carrier, AxiosError, CarrierCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Carrier, AxiosError, CarrierCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(carriersKeys.lists());
        },
    });
};

export const useCarrierUpdate = (options?: UseMutationOptions<Carrier, AxiosError, CarrierUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Carrier, AxiosError, CarrierUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(carriersKeys.detail(variables.id));

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(carriersKeys.lists());
        },
    });
};

export const useCarrierRemove = (options?: UseMutationOptions<undefined, AxiosError, CarrierIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, CarrierIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(carriersKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(carriersKeys.lists());
        },
    });
};
