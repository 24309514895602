import { parseStringToBoolean } from "../helpers";

export default Object.freeze({
    PAGE_SIZE: 50,
    API: Object.freeze({
        BASE_URL: import.meta.env.VITE_API_BASE_URL || "/api",
        KEY: import.meta.env.VITE_API_KEY || "",
    }),
    SEO: Object.freeze({
        BASE_URL: "",
        SITE_NAME: "Front-end boilerplate",
        DEFAULT_TITLE: "Front-end boilerplate",
        DEFAULT_DESCRIPTION: "Boilerplate for all developments based on reactjs, ant design, react-query...",
    }),
    HEADER_PRODUCT_NAME: "Track & Trace",
    GOOGLE_ANALYTICS: import.meta.env.VITE_GOOGLE_ANALYTICS || "",
    ENABLE_DEBUG_LOGS: false, // import.meta.env.PROD',
    ENABLE_MAINTENANCE_CHECK: parseStringToBoolean(import.meta.env.VITE_ENABLE_MAINTENANCE_CHECK) ?? true, // checks if the API is responsive every minutes, if not, displays a maintenance page instead of the app
    DEBUG_LOGS_APP: "", // <app>_<service>
    VERSION: import.meta.env.VITE_VERSION || "",
    FACEBOOK_APP_ID: import.meta.env.VITE_FACEBOOK_APP_ID || "",
    MENU_POLLING_INTERVAL_IN_MS: 15000,
    NOTIFICATIONS_REFETCH_INTERVAL_IN_MS: 5000,
    PASSWORD_MINIMUM_LENGTH: 10,
    MAPBOX_TOKEN:
        import.meta.env.VITE_MAPBOX_TOKEN ||
        "pk.eyJ1Ijoic3RzcGVubGUiLCJhIjoiY2xvY3ZkaWNlMDJjeDJpcW5ucXNzdmY3ayJ9.6cNAVcH1avJNMHiVAUYbNg",
    MAPBOX_STYLE: "mapbox://styles/stspenle/clto93ovj01nt01pjclkma0p1",
    LOCAL_LOGIN_USERNAME: import.meta.env.VITE_LOCAL_LOGIN_USERNAME, // put in .env.local file
    LOCAL_LOGIN_PASSWORD: import.meta.env.VITE_LOCAL_LOGIN_PASSWORD, // put in .env.local file
    REGISTRATION_REGEX: new RegExp(/(^[A-Za-z]{2}-[0-9]{3}-[A-Z]{2}$)|(\d[0-9]{2,4} [A-Z]{1,2} [0-9]{2,3}$)/),
    EMAIL_REGEX: new RegExp(/^[a-zA-Z0-9](?:[a-zA-Z0-9._-]*[a-zA-Z0-9])*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/),
});
