import { CloseCircleOutlined } from "@ant-design/icons";
import { VFC } from "react";

import { defaultErrorMessage } from "../i18n";

const ImportError: VFC = () => (
    <div className="text-base font-medium">
        <CloseCircleOutlined className="text-2xl text-red-600 mr-16" /> {defaultErrorMessage}
    </div>
);

export default ImportError;
