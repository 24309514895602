/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 14 14">
        <g clipPath="url(#phone_svg__a)">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M3.974 1.604a.62.62 0 0 0-.563 0c-.066.034-.149.106-.514.471l-.099.099c-.349.348-.443.45-.518.583-.087.155-.16.436-.16.614.001.158.024.254.127.616a11.24 11.24 0 0 0 2.877 4.89c1.41 1.41 3.1 2.368 4.889 2.876.362.102.458.126.616.126.178 0 .459-.072.614-.16.134-.074.235-.168.583-.517l.099-.099c.365-.365.437-.448.47-.514a.62.62 0 0 0 0-.563c-.033-.066-.105-.149-.47-.515l.44-.44-.44.44-.122-.121c-.24-.24-.294-.29-.335-.316a.62.62 0 0 0-.679 0c-.04.027-.095.076-.335.316l-.015.015a2 2 0 0 1-.211.188l-.363-.507.363.507a1.27 1.27 0 0 1-1.074.183c-.1-.03-.182-.07-.246-.1l-.015-.007a9.7 9.7 0 0 1-2.668-1.894 9.7 9.7 0 0 1-1.901-2.683c-.03-.064-.07-.146-.1-.246l.598-.174-.598.174a1.27 1.27 0 0 1 .183-1.074 2 2 0 0 1 .188-.211l.015-.015c.24-.24.29-.294.316-.335a.62.62 0 0 0 0-.68c-.027-.04-.076-.094-.316-.334l-.121-.122c-.366-.365-.449-.437-.515-.47M2.849.493a1.87 1.87 0 0 1 1.687 0c.27.136.503.37.774.642l.06.06.12.12.04.04c.179.178.332.332.44.498l-.522.34.522-.34c.403.62.403 1.418 0 2.037-.108.166-.261.32-.44.498l-.106.107-.001.007.008.018.023.048c.403.84.954 1.628 1.652 2.326a8.5 8.5 0 0 0 2.326 1.652l-.27.561.27-.561.048.023.022.008h.003l.068-.068.04-.04c.178-.178.331-.331.497-.44a1.87 1.87 0 0 1 2.037 0c.166.109.32.262.498.44l.04.04-.441.44.44-.44.121.122.06.06c.271.27.506.504.642.773.268.53.268 1.156 0 1.687-.136.27-.37.503-.642.774l-.06.06-.098.097-.042.043c-.29.29-.512.512-.812.68-.343.193-.835.321-1.228.32-.343-.001-.594-.072-.917-.164l-.035-.01a12.5 12.5 0 0 1-5.43-3.194 12.5 12.5 0 0 1-3.194-5.43l-.01-.035C.947 3.97.876 3.718.875 3.375a2.7 2.7 0 0 1 .32-1.228c.168-.3.39-.522.68-.812l.043-.042.098-.098.06-.06c.27-.271.504-.506.773-.642l.278.55z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="phone_svg__a">
                <path fill="currentColor" d="M0 0h14v14H0z" />
            </clipPath>
        </defs>
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgPhone: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-phone", props.className)} />
));

export default SvgPhone;
