import { VFC } from "react";

import constants from "../config/constants";
import useEnv from "../hooks/useEnv";

const ProductName: VFC = () => {
    const env = useEnv();

    return (
        <span className="bg-brand-orange-600 uppercase rounded-4 py-4 px-12 text-base text-white inline-block leading-6 font-bold space-x-8">
            {constants.HEADER_PRODUCT_NAME}
            {env && ["development", "preprod"].includes(env) && (
                <>
                    {" - "}
                    <span className="text-xs">{env === "development" ? "LOCAL" : "PREPROD"}</span>
                </>
            )}
        </span>
    );
};

export default ProductName;
