import api from "./apiClient";
import { SearchPaginationQuery } from ".";
import { Trailer, ListResponse } from "./types";

// Controller Interface
export type TrailerCreatePayload = Pick<Trailer, "reference" | "registration" | "contract" | "type" | "location">;

export interface TrailerUpdatePayload extends TrailerCreatePayload {
    id?: Trailer["id"];
    status?: Trailer["status"];
}

export type TrailerIdPayload = Trailer["id"];

export type TrailerListPayload = SearchPaginationQuery & {
    contract?: Array<Trailer["contract"]>;
    status?: Array<Trailer["status"]>;
    type?: Trailer["type"];
};
export type TrailerListResponse = ListResponse<Trailer>;

// Routes
export const list = async (payload?: TrailerListPayload) => {
    return await api.get<TrailerListResponse>(`/trailers`, { params: payload }).then((response) => response?.data);
};

export const details = async (id?: TrailerIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.get<Trailer>(`/trailers/${id}`).then((response) => response?.data);
};

export const create = async (payload: TrailerCreatePayload) => {
    return await api.post(`/trailers`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: TrailerUpdatePayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.put<Trailer>(`/trailers/${id}`, payload).then((response) => response?.data);
};

export const remove = async (id?: TrailerIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.delete<undefined>(`/trailers/${id}`).then((response) => response?.data);
};
