/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillOpacity={0.85}
            d="M22 3.5a1.007 1.007 0 0 1 .8.4c.049.066.063.094 0 0 .13.173.2.384.2.6-.003-.076 0-.053 0 0v15a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-15a1.003 1.003 0 0 1 1-1zm-1 3-8.4 6.3a1 1 0 0 1-1.09.072l-.11-.072L3 6.5v12h18zm-16-1 7 5.25 7-5.25z"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgMail: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-mail", props.className)} />
));

export default SvgMail;
