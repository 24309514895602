/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 28 28">
        <path
            fill="currentColor"
            d="m13.426 15.168.326.003.599.01.534.016.476.024c1.125.073 1.776.236 2.454.581a5.83 5.83 0 0 1 2.55 2.55l.056.114.104.226.09.226q.022.058.041.115l.073.239.032.124.057.263.025.14.043.302.034.333.027.372.02.417.02.72.012 1.17L21 24.5c0 .644-.522 1.167-1.167 1.167h-17.5A1.167 1.167 0 0 1 1.167 24.5v-1.414l.004-.671.01-.599.016-.534.024-.477c.073-1.124.236-1.776.582-2.453a5.83 5.83 0 0 1 2.549-2.55c.723-.368 1.415-.529 2.685-.594l.504-.02.566-.013.634-.007zm10.222.634a5.83 5.83 0 0 1 2.55 2.55l.11.227.05.113.09.226q.023.058.042.115l.072.239q.017.06.033.124l.056.263q.027.137.048.287l.038.317.03.352.034.608.02.72.01.853.002 1.704a1.167 1.167 0 0 1-2.325.136L24.5 24.5l-.001-1.315-.01-1.114-.018-.662-.017-.37-.023-.322-.028-.278-.017-.124-.038-.225a3 3 0 0 0-.07-.285l-.057-.166-.066-.154-.036-.074a3.5 3.5 0 0 0-1.315-1.41l-.215-.12a1.167 1.167 0 1 1 1.06-2.079m-10.262 1.7H8.783l-.814.01-.669.02-.375.021-.326.028-.284.035-.246.044a3 3 0 0 0-.405.112l-.172.07-.08.04a3.5 3.5 0 0 0-1.53 1.529 2 2 0 0 0-.076.165l-.066.18q-.045.142-.08.313l-.043.246-.036.283-.027.326-.03.584-.017.714-.007 1.111h15.166l-.004-.743-.007-.522-.017-.664-.018-.371-.023-.321-.028-.278-.035-.24-.042-.21-.024-.095-.053-.175-.062-.16-.068-.143a3.5 3.5 0 0 0-1.53-1.53 2 2 0 0 0-.164-.075l-.18-.066a3 3 0 0 0-.313-.08l-.246-.044-.284-.035-.326-.027-.583-.03-.714-.017zM11.083 2.332a5.25 5.25 0 1 1 0 10.5 5.25 5.25 0 0 1 0-10.5m8.54.751a5.25 5.25 0 0 1 2.544 4.5 5.25 5.25 0 0 1-2.544 4.499 1.167 1.167 0 0 1-1.204-1.999 2.91 2.91 0 0 0 1.415-2.5 2.91 2.91 0 0 0-1.415-2.501 1.167 1.167 0 1 1 1.204-1.999m-8.54 1.583a2.917 2.917 0 1 0 0 5.833 2.917 2.917 0 0 0 0-5.833"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgPeoples: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-peoples", props.className)} />
));

export default SvgPeoples;
