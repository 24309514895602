import { Select, SelectProps } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { VFC, useMemo } from "react";

import { useRoleList } from "../queries/roles";
import { RoleSlug } from "../queries/api/types";
import { roleMap } from "../i18n/roles";

interface SelectUserRoleProps extends Omit<SelectProps, "options" | "onSearch"> {
    additionalOptions?: DefaultOptionType[];
    selectableRoles?: RoleSlug[];
}

const SelectUserRole: VFC<SelectUserRoleProps> = ({ additionalOptions = [], selectableRoles = RoleSlug, ...props }) => {
    const { data: roleListData } = useRoleList({ slug: Object.values(selectableRoles) });
    const selectOptions = useMemo<DefaultOptionType[]>(() => {
        return (
            roleListData?.items?.map(({ slug, id }) => ({
                label: roleMap.get(slug),
                value: id,
            })) || []
        );
    }, [roleListData?.items]);

    return <Select {...props} options={[...additionalOptions, ...selectOptions]} />;
};

export default SelectUserRole;
