/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6.794.778a.74.74 0 0 1 .869.396c.249.506.755.85 1.337.85s1.088-.344 1.337-.85a.74.74 0 0 1 .869-.396 8.16 8.16 0 0 1 3.41 1.979.8.8 0 0 1 .162.913 1.6 1.6 0 0 0-.153.686c0 .859.672 1.555 1.5 1.555h.02a.75.75 0 0 1 .727.544 8.888 8.888 0 0 1 .21 4.287.754.754 0 0 1-.808.618 2 2 0 0 0-.149-.007c-.828 0-1.5.696-1.5 1.555 0 .382.132.73.353 1.001a.8.8 0 0 1-.033 1.04 8.2 8.2 0 0 1-3.587 2.269.745.745 0 0 1-.929-.509c-.193-.628-.761-1.08-1.429-1.08s-1.236.452-1.43 1.08a.745.745 0 0 1-.928.509 8.2 8.2 0 0 1-3.587-2.268.8.8 0 0 1-.033-1.04c.22-.272.353-.62.353-1.002 0-.86-.672-1.555-1.5-1.555q-.075 0-.15.007a.754.754 0 0 1-.808-.618 8.9 8.9 0 0 1 .21-4.287c.1-.328.396-.55.727-.544h.021c.828 0 1.5-.696 1.5-1.555 0-.248-.055-.48-.153-.686a.8.8 0 0 1 .162-.913A8.16 8.16 0 0 1 6.794.778M4.776 3.563q.098.381.099.793c0 1.521-1.054 2.787-2.446 3.057a7.3 7.3 0 0 0-.135 2.415c1.459.212 2.581 1.51 2.581 3.08 0 .497-.113.967-.313 1.384.562.51 1.207.923 1.91 1.218.532-.863 1.465-1.436 2.528-1.436s1.996.573 2.529 1.436a6.7 6.7 0 0 0 1.91-1.218 3.2 3.2 0 0 1-.314-1.384c0-1.57 1.122-2.868 2.58-3.08a7.3 7.3 0 0 0-.134-2.415c-1.392-.27-2.446-1.536-2.446-3.057q0-.412.099-.793a6.7 6.7 0 0 0-1.908-1.117A2.95 2.95 0 0 1 9 3.58a2.95 2.95 0 0 1-2.315-1.133 6.7 6.7 0 0 0-1.909 1.117m.849 5.458c0-1.933 1.511-3.499 3.375-3.499s3.375 1.566 3.375 3.499c0 1.932-1.511 3.498-3.375 3.498S5.625 10.953 5.625 9.02M9 7.077c-1.036 0-1.875.87-1.875 1.944s.84 1.943 1.875 1.943 1.875-.87 1.875-1.943c0-1.074-.84-1.944-1.875-1.944"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgSettings: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-settings", props.className)} />
));

export default SvgSettings;
