/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18">
        <path
            fill="#24CAFF"
            d="M9 .75a.75.75 0 0 1 .75.75v1.564l.97-.97a.75.75 0 1 1 1.06 1.061l-2.03 2.03v2.516l2.034-1.174 1.004-3.014a.75.75 0 1 1 1.423.474l-.47 1.41 1.364-.788a.75.75 0 0 1 .75 1.3l-1.528.882 1.586.352a.75.75 0 1 1-.326 1.464L12.405 7.9 10.51 8.995l1.908 1.102 3.17-.704a.75.75 0 0 1 .326 1.464l-1.574.35 1.548.893a.75.75 0 0 1-.75 1.3l-1.4-.808.474 1.42a.75.75 0 0 1-1.423.475l-1.008-3.026-2.03-1.172v2.525l2.03 2.03a.75.75 0 1 1-1.06 1.061l-.97-.97V16.5a.75.75 0 0 1-1.5 0v-1.564l-.97.97a.75.75 0 0 1-1.06-1.061l2.03-2.03v-2.516l-2.034 1.175-1.005 3.013a.75.75 0 0 1-1.423-.474l.47-1.41-1.363.788a.75.75 0 1 1-.75-1.299l1.528-.882-1.586-.353a.75.75 0 1 1 .326-1.464l3.182.707 1.914-1.105-1.902-1.098-3.194.71a.75.75 0 1 1-.326-1.464l1.598-.355L2.147 5.9a.75.75 0 0 1 .75-1.3l1.357.784-.466-1.397a.75.75 0 0 1 1.423-.474l1.001 3.002L8.25 7.69V5.186l-2.03-2.03a.75.75 0 1 1 1.06-1.061l.97.97V1.5A.75.75 0 0 1 9 .75"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgSnowflake: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-snowflake", props.className)} />
));

export default SvgSnowflake;
