/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9 1.125a.75.75 0 0 1 .65.374l8.25 14.25a.75.75 0 0 1-.65 1.126H.75a.75.75 0 0 1-.65-1.126L8.35 1.5A.75.75 0 0 1 9 1.125m-6.95 14.25h13.9L9 3.372zm6.95-9a.75.75 0 0 1 .75.75l.003 3.75a.75.75 0 1 1-1.5 0l-.003-3.75a.75.75 0 0 1 .75-.75m0 6a.75.75 0 0 1 .75.75v.375a.75.75 0 0 1-1.5 0v-.375a.75.75 0 0 1 .75-.75"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgCaution: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-caution", props.className)} />
));

export default SvgCaution;
