/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 14 14">
        <g clipPath="url(#message_svg__a)">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M.583 7a6.417 6.417 0 1 1 4.004 5.947l-.144-.057H4.44l-.118.02-2.283.38-.018.003a4 4 0 0 1-.319.044 1 1 0 0 1-.466-.068.96.96 0 0 1-.505-.505 1 1 0 0 1-.068-.466c.008-.1.027-.216.044-.319l.003-.018.38-2.283.02-.118v-.003l-.003-.009-.054-.135A6.4 6.4 0 0 1 .583 7M7 1.867A5.133 5.133 0 0 0 2.242 8.93l.011.03c.04.095.086.21.11.316.022.096.031.181.031.28a2 2 0 0 1-.038.333l-.35 2.105 2.105-.35.025-.005c.089-.015.199-.033.308-.033.099 0 .183.01.28.03a2 2 0 0 1 .346.122A5.133 5.133 0 1 0 7 1.866M3.15 7a.962.962 0 1 1 1.925 0A.962.962 0 0 1 3.15 7m2.887 0a.962.962 0 1 1 1.925 0 .962.962 0 0 1-1.925 0m2.888 0a.962.962 0 1 1 1.925 0 .962.962 0 0 1-1.925 0"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="message_svg__a">
                <path fill="currentColor" d="M0 0h14v14H0z" />
            </clipPath>
        </defs>
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgMessage: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-message", props.className)} />
));

export default SvgMessage;
