/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16">
        <path
            fill="currentColor"
            d="M5.285 2.141h-.142a.143.143 0 0 0 .142-.143zh5.429v-.143c0 .079.064.143.143.143h-.143v1.286H12V1.998c0-.63-.513-1.143-1.143-1.143H5.143C4.513.855 4 1.368 4 1.998v1.429h1.285zm9 1.286H1.714a.57.57 0 0 0-.571.571v.572c0 .078.064.143.142.143h1.079l.441 9.339c.029.609.532 1.09 1.141 1.09h8.107c.611 0 1.113-.48 1.141-1.09l.441-9.34h1.079a.143.143 0 0 0 .143-.142v-.572a.57.57 0 0 0-.572-.571m-2.37 10.429H4.085l-.432-9.143h8.696z"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgDeleteOutlined: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-delete-outlined", props.className)} />
));

export default SvgDeleteOutlined;
