import { RoleSlug } from "../queries/api/types";

export const roleMap = new Map([
    [RoleSlug.superAdmin, "Super Administateur"],
    [RoleSlug.operator, "Exploitant"],
    [RoleSlug.admin, "Administrateur"],
    [RoleSlug.driver, "Chauffeur"],
    [RoleSlug.shippingManager, "Resp. Expédition"],
    [RoleSlug.packagingArea, "Aire Emballages"],
    [RoleSlug.store, "Magasin"],
]);
