/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillOpacity={0.85}
            fillRule="evenodd"
            d="M9.607 5.293a1 1 0 0 1 0 1.414L5.314 11H20.9a1 1 0 1 1 0 2H5.313l4.293 4.293a1 1 0 1 1-1.415 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.415 0"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgArrowLeft: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-arrow-left", props.className)} />
));

export default SvgArrowLeft;
