import api from "./apiClient";
import { SearchPaginationQuery } from ".";
import { ValueListItem, ListResponse, ValueList } from "./types";

// Controller Interface
export interface ValueListItemCreatePayload {
    valueListSlug?: ValueList["slug"];
    title: string;
}

export interface ValueListItemUpdatePayload extends Omit<ValueListItem, "id"> {
    valueListSlug?: ValueList["slug"];
    id?: ValueListItem["id"];
}

export interface ValueListItemIdPayload {
    valueListSlug?: ValueList["slug"];
    id?: ValueListItem["id"];
}

export type ValueListItemListPayload = SearchPaginationQuery & {
    valueListSlug?: ValueList["slug"];
    warehouseValueListItemId?: ValueListItem["id"];
};
export type ValueListItemListResponse = ListResponse<ValueListItem>;

// Routes
export const list = async ({ valueListSlug, ...payload }: ValueListItemListPayload) => {
    if (!valueListSlug) {
        throw new Error("missing valueListSlug");
    }

    return await api
        .get<ValueListItemListResponse>(`/valueLists/${valueListSlug}/items`, { params: payload })
        .then((response) => response?.data);
};

export const create = async ({ valueListSlug, ...payload }: ValueListItemCreatePayload) => {
    if (!valueListSlug) {
        throw new Error("missing valueListSlug");
    }

    return await api.post(`/valueLists/${valueListSlug}/items`, payload).then((response) => response?.data);
};

export const update = async ({ id, valueListSlug, ...payload }: ValueListItemUpdatePayload) => {
    if (!id || !valueListSlug) {
        throw new Error("missing id or valueListSlug");
    }
    return await api
        .put<ValueListItem>(`/valueLists/${valueListSlug}/items/${id}`, payload)
        .then((response) => response?.data);
};

export const details = async ({ valueListSlug, id }: ValueListItemIdPayload) => {
    if (!id || !valueListSlug) {
        throw new Error("missing id or valueListSlug");
    }
    return await api.get<ValueListItem>(`/valueLists/${valueListSlug}/items/${id}`).then((response) => response?.data);
};

export const remove = async ({ valueListSlug, id }: ValueListItemIdPayload) => {
    if (!id || !valueListSlug) {
        throw new Error("missing id or valueListSlug");
    }
    return await api.delete<undefined>(`/valueLists/${valueListSlug}/items/${id}`).then((response) => response?.data);
};
