/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 14 14">
        <g clipPath="url(#qr-code_svg__a)">
            <path
                fill="currentColor"
                d="M6.265.583H1.12a.534.534 0 0 0-.535.535v5.147c0 .073.06.133.134.133h5.547c.074 0 .134-.06.134-.133V.717a.134.134 0 0 0-.134-.134M5.33 5.33H1.653V1.653H5.33zM3.024 4.092h.935c.074 0 .134-.06.134-.133v-.936a.134.134 0 0 0-.134-.134h-.935a.134.134 0 0 0-.134.134v.936c0 .073.06.133.134.133m3.241 3.51H.718a.134.134 0 0 0-.134.133v5.147c0 .296.239.535.535.535h5.146c.074 0 .134-.06.134-.134V7.735a.134.134 0 0 0-.134-.133m-.935 4.745H1.653V8.671H5.33zm-2.306-1.236h.935c.074 0 .134-.06.134-.134v-.936a.134.134 0 0 0-.134-.133h-.935a.134.134 0 0 0-.134.133v.936c0 .074.06.134.134.134M12.883.583H7.736a.134.134 0 0 0-.134.134v5.548c0 .073.06.133.134.133h5.548c.073 0 .133-.06.133-.133V1.118a.534.534 0 0 0-.534-.535m-.535 4.746H8.672V1.653h3.676zm-2.306-1.237h.936c.073 0 .133-.06.133-.133v-.936a.134.134 0 0 0-.133-.134h-.936a.134.134 0 0 0-.134.134v.936c0 .073.06.133.134.133m3.242 3.51h-.802a.134.134 0 0 0-.134.133v2.24h-1.304v-2.24a.134.134 0 0 0-.133-.133H7.736a.134.134 0 0 0-.134.133v5.548c0 .074.06.134.134.134h.802c.073 0 .134-.06.134-.134V9.206h1.303v1.704c0 .074.06.134.134.134h3.175c.073 0 .133-.06.133-.134V7.735a.134.134 0 0 0-.133-.133m-2.373 4.745h-.802a.134.134 0 0 0-.134.134v.802c0 .074.06.134.134.134h.802c.073 0 .133-.06.133-.134v-.802a.134.134 0 0 0-.133-.134m2.373 0h-.802a.134.134 0 0 0-.134.134v.802c0 .074.06.134.134.134h.802c.073 0 .133-.06.133-.134v-.802a.134.134 0 0 0-.133-.134"
            />
        </g>
        <defs>
            <clipPath id="qr-code_svg__a">
                <path fill="currentColor" d="M0 0h14v14H0z" />
            </clipPath>
        </defs>
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgQrCode: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-qr-code", props.className)} />
));

export default SvgQrCode;
