/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.613 1.395a.667.667 0 0 0-.946.606v10c0 .26.15.496.387.605l4.328 1.998a.66.66 0 0 0 .552.007l4.39-1.88 4.063 1.875a.667.667 0 0 0 .946-.605V4a.67.67 0 0 0-.387-.606l-4.33-1.998a.67.67 0 0 0-.55-.007L5.676 3.27zm8.054 1.617L6.333 4.44v8.55l3.334-1.429zM11 11.574l3 1.385V4.427l-3-1.384zM2 3.043l3 1.384v8.532l-3-1.385z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgMap: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-map", props.className)} />
));

export default SvgMap;
