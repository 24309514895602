import { VFC, useState } from "react";
import { Divider, Popover } from "antd";
import Button from "@oneui/button";

import { useAuth } from "../context/AuthContext";
import { formatPhoneNumber, getFullName } from "../helpers";
import { Copy, Mail, Phone } from "./icons";
import { successMessage } from "../helpers/message";

const HeaderWareHouseInfos: VFC = () => {
    const { user } = useAuth();
    const { warehouse } = user || {};
    const [phoneOpen, setPhoneOpen] = useState(false);
    const [mailOpen, setMailOpen] = useState(false);

    const hidePhone = () => {
        setPhoneOpen(false);
    };

    const handlePhoneOpenChange = (newState: boolean) => {
        setPhoneOpen(newState);
    };

    const hideMail = () => {
        setMailOpen(false);
    };

    const handleMailOpenChange = (newState: boolean) => {
        setMailOpen(newState);
    };

    if (!warehouse) {
        return null;
    }

    return (
        <div className="flex leading-5 items-center">
            <Divider type="vertical" className="!h-32 !mx-24" />
            <div className="flex gap-32 mr-16">
                <div>
                    <p className="uppercase text-2xs text-brand-neutral-grey font-extrabold">Votre entrepôt</p>
                    <p className="text-brand-blue-600 font-bold text-base">{warehouse.fields?.warehouse}</p>
                </div>
                <div>
                    <p className="uppercase text-2xs text-brand-neutral-grey font-extrabold">Contact entrepôt</p>
                    <p className="text-brand-blue-600 font-bold text-base">{getFullName(warehouse.fields) ?? "-"}</p>
                </div>
            </div>
            <div className="flex gap-8">
                <Popover
                    open={phoneOpen}
                    onOpenChange={handlePhoneOpenChange}
                    content={
                        <span className="flex items-center gap-16">
                            {formatPhoneNumber(String(warehouse.fields?.phoneNumber))}
                            <Copy
                                onClick={() =>
                                    navigator.clipboard.writeText(String(warehouse.fields?.phoneNumber)).then(() => {
                                        hidePhone();
                                        successMessage({
                                            content: "Numéro de téléphone copié dans le presse-papier",
                                        });
                                    })
                                }
                            />
                        </span>
                    }
                >
                    <Button size="small" type="secondary" iconOnly={<Phone />} />
                </Popover>
                <Popover
                    open={mailOpen}
                    onOpenChange={handleMailOpenChange}
                    content={
                        <span className="flex items-center gap-16">
                            {warehouse.fields?.email}
                            <Copy
                                onClick={() =>
                                    navigator.clipboard.writeText(String(warehouse.fields?.email)).then(() => {
                                        hideMail();
                                        successMessage({ content: "Mail copié dans le presse-papier" });
                                    })
                                }
                            />
                        </span>
                    }
                >
                    <Button size="small" type="secondary" iconOnly={<Mail />} />
                </Popover>
            </div>
            <Divider type="vertical" className="!h-32 !mx-24" />
        </div>
    );
};

export default HeaderWareHouseInfos;
