import { VFC, useCallback, useMemo, useState } from "react";
import { Empty, Select, SelectProps, Spin } from "antd";
import { DefaultOptionType } from "antd/lib/select";

import { useStoreList } from "../queries/stores";
import { useDebounce } from "../hooks";
import { StoreListPayload } from "../queries/api/stores";

interface SelectStoreProps extends Omit<SelectProps, "options" | "onSearch"> {
    additionalOptions?: DefaultOptionType[];
    queryPayload?: StoreListPayload;
}

const SelectStore: VFC<SelectStoreProps> = ({ additionalOptions = [], queryPayload, ...props }) => {
    const [query, setQuery] = useState<string | undefined>(undefined);
    const search = useDebounce(query, 500);
    const { data: storeListData, isLoading } = useStoreList({
        pageSize: 20,
        search,
        ...queryPayload,
    });
    const options: SelectProps["options"] = useMemo(() => {
        return (storeListData?.items || []).map((store) => ({
            label: `${store.reference} - ${store.name}`,
            value: store.id,
        }));
    }, [storeListData?.items]);

    const onSelect = useCallback(() => {
        setQuery(undefined);
    }, []);

    return (
        <Select
            options={[...additionalOptions, ...options]}
            showSearch
            onSearch={(query) => setQuery(query)}
            notFoundContent={isLoading ? <Spin size="small" /> : <Empty />}
            filterOption={false}
            onSelect={onSelect}
            {...props}
        />
    );
};

export default SelectStore;
