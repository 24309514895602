import api from "./apiClient";
import { SearchPaginationQuery } from ".";
import { Carrier, CarrierAttachment, ListResponse } from "./types";

// Controller Interface
export type CarrierCreatePayload = Pick<Carrier, "name" | "address" | "phone" | "attachment">;

export interface CarrierUpdatePayload extends CarrierCreatePayload {
    id?: Carrier["id"];
}

export type CarrierIdPayload = Carrier["id"];

export type CarrierListPayload = SearchPaginationQuery & {
    reference?: Carrier["reference"];
    name?: Carrier["name"];
    address?: Carrier["address"];
    phone?: Carrier["phone"];
    attachment?: CarrierAttachment;
    availableFrom?: string;
    availableTo?: string;
};
export type CarrierListResponse = ListResponse<Carrier>;

// Routes
export const list = async (payload?: CarrierListPayload) => {
    return await api.get<CarrierListResponse>(`/carriers`, { params: payload }).then((response) => response?.data);
};

export const create = async (payload: CarrierCreatePayload) => {
    return await api.post(`/carriers`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: CarrierUpdatePayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.put<Carrier>(`/carriers/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: CarrierIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.get<Carrier>(`/carriers/${id}`).then((response) => response?.data);
};

export const remove = async (id?: CarrierIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.delete<undefined>(`/carriers/${id}`).then((response) => response?.data);
};
