/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16">
        <path
            fill="currentColor"
            d="M5.665 1.895h-1.69c-1.317 0-2.261 1.247-1.84 2.496.842 2.497 1.975 4.709 3.451 6.185 1.477 1.476 3.688 2.609 6.185 3.451 1.25.422 2.496-.522 2.496-1.84v-1.69c0-.485-.262-.93-.685-1.166l-1.474-.819-.106-.053a1.33 1.33 0 0 0-1.138.026l-1.347.673-.032-.01a4.1 4.1 0 0 1-1.16-.6 3.6 3.6 0 0 1-.716-.716 4 4 0 0 1-.535-.981l-.063-.179L7 6.64l.673-1.345a1.33 1.33 0 0 0-.027-1.244L6.83 2.581a1.33 1.33 0 0 0-1.166-.686M3.975 3.23h1.69l.816 1.469-.786 1.57a.67.67 0 0 0-.058.426c.099.506.369 1.22.904 1.936a4.9 4.9 0 0 0 .984.986c.718.538 1.433.808 1.94.906a.67.67 0 0 0 .425-.059l1.57-.785 1.474.818v1.692c0 .421-.368.7-.736.576-2.319-.782-4.371-1.833-5.669-3.131S4.181 6.283 3.4 3.965c-.125-.368.154-.736.576-.736"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgPhoneTelephone: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-phone-telephone", props.className)} />
));

export default SvgPhoneTelephone;
