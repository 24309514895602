import { VFC } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Avatar, Menu, Button } from "antd";
import { useModal } from "@ebay/nice-modal-react";

import { getRoute, RoutePathName } from "../routes";
import { useAuth } from "../context/AuthContext";
import { Gear, Logout } from "./icons";
import { RoleSlug } from "../queries/api/types";
import ChangeRoleModal from "./ChangeRoleModal";
import { roleMap } from "../i18n/roles";

const UserDropdown: VFC = () => {
    const { user, logout } = useAuth();
    const isSuperAdmin = user?.mainRole?.slug === RoleSlug.superAdmin;
    const changeRoleModal = useModal(ChangeRoleModal);
    const menu = (
        <Menu>
            {isSuperAdmin && (
                <Menu.Item key="changeRole" icon={<Gear />} onClick={() => changeRoleModal.show()}>
                    Changer de rôle
                </Menu.Item>
            )}

            <Menu.Item key="logout" icon={<Logout />}>
                <Link
                    to={getRoute(RoutePathName.login)}
                    onClick={() => {
                        logout.mutate();
                    }}
                >
                    Déconnexion
                </Link>
            </Menu.Item>
        </Menu>
    );

    return user ? (
        <Dropdown trigger={["click"]} overlay={menu} placement="topRight">
            <Button id="user-menu-button" type="text">
                <Avatar size={40}>{user?.firstName?.substring(0, 1)}</Avatar>
                <div id="user-menu-button-content">
                    <span>{user?.firstName}</span>
                    {isSuperAdmin && user.role?.slug && <span>{`(${roleMap.get(user.role.slug)})`}</span>}
                </div>
            </Button>
        </Dropdown>
    ) : null;
};

export default UserDropdown;
