/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 22 28">
        <path
            fill="currentColor"
            d="m1.81 16.272-.055-.076.007.014A10.13 10.13 0 0 1 .109 9.03C.482 6.524 1.79 4.23 3.79 2.572S8.35 0 11 0s5.21.913 7.211 2.572 3.308 3.952 3.68 6.458a10.13 10.13 0 0 1-1.652 7.18l-.048.07q-.11.137-.224.292l-7.944 10.919c-.232.319-.614.509-1.02.509s-.788-.19-1.02-.51L2.017 16.565c-.07-.102-.14-.196-.208-.292"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgPin: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-pin", props.className)} />
));

export default SvgPin;
