/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 28 28">
        <path
            fill="currentColor"
            d="M4.667 26.833c-.599 0-1.092-.45-1.16-1.03l-.007-.137V13.606a5 5 0 0 1-.699-.306l-.252-.143-.816-.49a1.17 1.17 0 0 1-.566-1V7c0-.644.522-1.167 1.166-1.167L3.5 5.832V2.333c0-.598.45-1.091 1.03-1.159l.137-.007h18.666c.599 0 1.092.45 1.16 1.03l.007.136v3.499l1.167.001c.598 0 1.091.45 1.159 1.03l.007.137v4.666c0 .41-.215.79-.566 1l-.816.49a5.3 5.3 0 0 1-.95.448v12.063c0 .598-.451 1.09-1.032 1.158l-.136.008zm15.166-13.806-.215.13a5.25 5.25 0 0 1-5.15.142l-.252-.143-.216-.13-.216.13a5.25 5.25 0 0 1-5.15.143l-.252-.143-.215-.13-.216.13a5.25 5.25 0 0 1-2.117.716V24.5h4.083v-5.834c0-.598.45-1.09 1.03-1.158l.136-.008h5.834c.644 0 1.166.522 1.166 1.166V24.5h4.084V13.872a5.25 5.25 0 0 1-2.118-.716zm-4.083 6.806h-3.5V24.5h3.5zm7.564-11.668L3.5 8.167v2.84l.25.149a2.92 2.92 0 0 0 2.813.103l.188-.103.815-.49c.37-.222.832-.222 1.201 0l.816.49a2.92 2.92 0 0 0 3.001 0l.816-.49c.37-.222.83-.222 1.2 0l.816.49a2.92 2.92 0 0 0 3.001 0l.816-.49c.37-.222.831-.222 1.2 0l.817.49a2.92 2.92 0 0 0 3 0l.25-.151V8.166h-1.186M22.167 3.5H5.833v2.332h16.334z"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgStore: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-store", props.className)} />
));

export default SvgStore;
