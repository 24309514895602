/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18">
        <g clipPath="url(#copy_svg__a)">
            <path
                fill="gray"
                d="M15.428 0H4.66a.16.16 0 0 0-.16.16v1.126c0 .088.071.16.16.16h9.964v13.822c0 .088.072.16.16.16h1.126c.088 0 .16-.072.16-.16V.643A.64.64 0 0 0 15.429 0m-2.572 2.571H2.571a.64.64 0 0 0-.643.643v10.662c0 .17.068.333.189.454l3.481 3.481q.066.066.149.11v.039h.084q.106.04.221.04h6.804a.64.64 0 0 0 .643-.643V3.214a.64.64 0 0 0-.643-.643M5.745 15.915l-1.73-1.732h1.73zm6.308.639H7.03V13.7a.803.803 0 0 0-.803-.804H3.374v-8.88h8.679z"
            />
        </g>
        <defs>
            <clipPath id="copy_svg__a">
                <path fill="currentColor" d="M0 0h18v18H0z" />
            </clipPath>
        </defs>
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgCopy: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-copy", props.className)} />
));

export default SvgCopy;
