/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8.591 1.621a.75.75 0 0 1 .818 0l7.5 4.875a.75.75 0 0 1 .341.629v8.625a.75.75 0 0 1-.75.75h-15a.75.75 0 0 1-.75-.75V7.125a.75.75 0 0 1 .341-.629zM5.25 15h1.5V9.228l-1.5.938zm3 0V7.875a.75.75 0 0 0-1.147-.636l-3 1.875a.75.75 0 0 0-.353.636V15h-1.5V7.532L9 3.145l6.75 4.387V15h-1.5V9.75a.75.75 0 0 0-.352-.636l-3-1.875a.75.75 0 0 0-1.148.636V15zm3 0h1.5v-4.834l-1.5-.938z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgBuildingThree: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-building-three", props.className)} />
));

export default SvgBuildingThree;
