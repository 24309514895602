import api from "./apiClient";
import { SearchPaginationQuery } from ".";
import { ListResponse, Loading, LoadingTask, LoadingTaskStatus, ValueListItem } from "./types";

export type LoadingIdPayload = LoadingTask["id"];
export type LoadingListPayload = SearchPaginationQuery & {
    from?: string;
    to?: string;
    status?: LoadingTaskStatus;
    activity?: ValueListItem["id"];
    withoutPlatform?: boolean;
};
export interface LoadingUpdatePayload {
    id: Loading["id"];
    platform: ValueListItem["id"] | null;
    loader: string | null;
}
export type LoadingListResponse = ListResponse<Loading>;

export interface AddLoadingCommentPayload {
    loadingId: LoadingTask["id"];
    shippingServiceComment: string | null;
}

// Routes
export const list = async (payload?: LoadingListPayload) => {
    return await api.get<LoadingListResponse>(`/loadings`, { params: payload }).then((response) => response?.data);
};

export const details = async (id?: LoadingIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.get<Loading>(`/loadings/${id}`).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: LoadingUpdatePayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.put<Loading>(`/loadings/${id}`, payload).then((response) => response?.data);
};

export const comment = async ({ loadingId, ...payload }: AddLoadingCommentPayload) => {
    if (!loadingId) {
        throw new Error("missing id");
    }
    return await api.post<Loading>(`/loadings/${loadingId}/comment`, payload).then((response) => response?.data);
};
