/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.195 2.195c.26-.26.683-.26.943 0L8 7.057l4.862-4.862a.667.667 0 1 1 .943.943L8.943 8l4.862 4.862a.667.667 0 1 1-.943.943L8 8.943l-4.862 4.862a.667.667 0 1 1-.943-.943L7.057 8 2.195 3.138a.667.667 0 0 1 0-.943"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgCloseIcon: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-close-icon", props.className)} />
));

export default SvgCloseIcon;
