/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 14 8">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M.293.293a1 1 0 0 1 1.414 0L7 5.586 12.293.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgChevronDown: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-chevron-down", props.className)} />
));

export default SvgChevronDown;
