import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import {
    list,
    TrailerListPayload,
    TrailerListResponse,
    TrailerIdPayload,
    TrailerUpdatePayload,
    update,
    TrailerCreatePayload,
    create,
    details,
    remove,
} from "./api/trailers";
import { Trailer } from "./api/types";

export const trailersKeys = {
    all: ["trailers"],
    lists: () => [...trailersKeys.all, "list"],
    list: (params?: TrailerListPayload) => [...trailersKeys.lists(), params],
    details: () => [...trailersKeys.all, "details"],
    detail: (id?: TrailerIdPayload) => [...trailersKeys.details(), id],
};

export const useTrailerList = <TData = TrailerListResponse>(
    params?: TrailerListPayload,
    options?: UseQueryOptions<TrailerListResponse, AxiosError, TData>
) => {
    return useQuery<TrailerListResponse, AxiosError, TData>(trailersKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
        ...options,
    });
};

export const useTrailerDetails = <TData = Trailer>(
    id?: TrailerIdPayload,
    options?: UseQueryOptions<Trailer, AxiosError, TData>
) => {
    return useQuery<Trailer, AxiosError, TData>(trailersKeys.detail(id), async () => await details(id), options);
};

export const useTrailerUpdate = (options?: UseMutationOptions<Trailer, AxiosError, TrailerUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Trailer, AxiosError, TrailerUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(trailersKeys.detail(variables.id));

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(trailersKeys.lists());
        },
    });
};

export const useTrailerCreate = (options?: UseMutationOptions<Trailer, AxiosError, TrailerCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Trailer, AxiosError, TrailerCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(trailersKeys.lists());
        },
    });
};

export const useTrailerRemove = (options?: UseMutationOptions<undefined, AxiosError, TrailerIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, TrailerIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(trailersKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(trailersKeys.lists());
        },
    });
};
