import { AxiosError } from "axios";
import {
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
} from "@tanstack/react-query";

import {
    list,
    NotificationListPayload,
    NotificationListResponse,
    NotificationUpdatePayload,
    update,
} from "./api/notifications";
import { Notification } from "./api/types";

export const notificationsKeys = {
    all: ["notifications"],
    lists: () => [...notificationsKeys.all, "list"],
    listInfinite: (params?: NotificationListPayload) => [...notificationsKeys.lists(), params],
    list: (params?: NotificationListPayload) => [...notificationsKeys.lists(), params],
};

export const useNotificationList = <TData = NotificationListResponse>(
    params: NotificationListPayload,
    options?: UseQueryOptions<NotificationListResponse, AxiosError, TData>
) => {
    return useQuery<NotificationListResponse, AxiosError, TData>(notificationsKeys.list(params), list, {
        ...options,
        keepPreviousData: true,
    });
};

export const useNotificationListInfinite = <TData = NotificationListResponse>(
    payload: NotificationListPayload,
    options?: UseInfiniteQueryOptions<
        NotificationListResponse,
        AxiosError,
        TData,
        NotificationListResponse,
        Array<string | NotificationListPayload | undefined>
    >
) => {
    return useInfiniteQuery<
        NotificationListResponse,
        AxiosError,
        TData,
        Array<string | NotificationListPayload | undefined>
    >(notificationsKeys.listInfinite(payload), list, options);
};

export const useNotificationUpdate = (
    options?: UseMutationOptions<Notification, AxiosError, NotificationUpdatePayload>
) => {
    return useMutation<Notification, AxiosError, NotificationUpdatePayload>(
        async (params) => await update(params),
        options
    );
};
