import { Layout } from "antd";
import { FC } from "react";
import { useRouteMatch } from "react-router-dom";

import { LayoutProvider } from "../context/LayoutContext";
import { classNames } from "../helpers";
import { getRawRoute, RoutePathName } from "../routes";
import Header from "./Header";
import MainMenu from "./MainMenu";

const MainLayout: FC = ({ children }) => {
    const isChooseHubPage = useRouteMatch(getRawRoute(RoutePathName.chooseHub));

    return (
        <LayoutProvider>
            <Layout id="main-layout">
                <Header />
                <Layout>
                    {!isChooseHubPage && <MainMenu />}
                    <Layout.Content id="main-content" className={classNames(!!isChooseHubPage && "choose-hub-content")}>
                        {children}
                    </Layout.Content>
                </Layout>
            </Layout>
        </LayoutProvider>
    );
};

export default MainLayout;
