/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.529 3.53c.26-.261.682-.261.942 0l4 4c.26.26.26.682 0 .942l-4 4a.667.667 0 0 1-.942-.943l2.861-2.862H2a.667.667 0 0 1 0-1.333h10.39L9.53 4.472a.667.667 0 0 1 0-.943"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgArrowRight: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-arrow-right", props.className)} />
));

export default SvgArrowRight;
