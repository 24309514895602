/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 25">
        <path
            fill="currentColor"
            fillOpacity={0.85}
            fillRule="evenodd"
            d="M0 6a1 1 0 0 1 1-1h14.5a1 1 0 0 1 1 1v2.5h3.286a1 1 0 0 1 .797.397l3.215 4.25a1 1 0 0 1 .202.603V18a1 1 0 0 1-1 1h-2.67a3.001 3.001 0 0 1-5.66 0H8.83a3.001 3.001 0 0 1-5.66 0H1a1 1 0 0 1-1-1zm3.17 11a3.001 3.001 0 0 1 5.66 0h5.67V7H2v10zm13.33-1.83V10.5h2.788L22 14.086V17h-1.67a3 3 0 0 0-3.83-1.83M6 17a1 1 0 1 0 0 2 1 1 0 0 0 0-2m11.5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgTruck: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-truck", props.className)} />
));

export default SvgTruck;
