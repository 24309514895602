/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16">
        <g clipPath="url(#edit_svg__a)">
            <path
                fill="currentColor"
                d="M3.459 12.284a1 1 0 0 0 .107-.009l3.003-.527a.18.18 0 0 0 .095-.05l7.57-7.57a.18.18 0 0 0 0-.251L11.266.907a.18.18 0 0 0-.127-.052.18.18 0 0 0-.127.052l-7.57 7.57a.2.2 0 0 0-.05.095l-.526 3.003a.6.6 0 0 0 .168.532.6.6 0 0 0 .425.177M4.662 9.17l6.477-6.475 1.309 1.309-6.477 6.475-1.587.28zm9.91 4.614H1.427a.57.57 0 0 0-.571.571v.643c0 .079.064.143.143.143h14a.143.143 0 0 0 .143-.143v-.643a.57.57 0 0 0-.572-.571"
            />
        </g>
        <defs>
            <clipPath id="edit_svg__a">
                <path fill="currentColor" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgEdit: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-edit", props.className)} />
));

export default SvgEdit;
