/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 29">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M0 1.384s5.957-2.886 17.87.436c1.143.318 1.699 1.584 1.347 2.718-1.943 6.265.783 9.973.783 11.92-7.778-1.077-15.556-1.615-17.778 0V28.3H0z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgFinalPin: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-final-pin", props.className)} />
));

export default SvgFinalPin;
