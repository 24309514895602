/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4.5.75a.75.75 0 0 1 .75.75H15a.75.75 0 0 1 .67 1.085L14.339 5.25l1.333 2.665A.75.75 0 0 1 15 9H5.25v6.75H6a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1 0-1.5h.75V1.5A.75.75 0 0 1 4.5.75m.75 6.75h8.537l-.958-1.915a.75.75 0 0 1 0-.67L13.786 3H5.25z"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgFlag: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-flag", props.className)} />
));

export default SvgFlag;
