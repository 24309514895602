import { RcFile } from "antd/lib/upload";
import { serialize } from "object-to-formdata";

import api from "./apiClient";
import { Role, Store, User, ValueListItem } from "./types";
import { fbLogin } from "../../helpers/facebook";
import { HttpError } from "../../helpers/errors";

export interface LoginPayload {
    username?: User["email"];
    password?: string;
    fbId?: string;
    fbToken?: string;
}
export interface UpdateRolePayload {
    role: Role["id"];
    warehouse?: ValueListItem["id"];
    store?: Store["id"];
}

export const login = async (payload: LoginPayload) =>
    await api.post("/auth", payload).then((response) => response?.data);

export const facebookLogin = async () => {
    const fbResponse = await fbLogin();

    if (!fbResponse || !fbResponse?.authResponse?.userID || !fbResponse?.authResponse?.accessToken) {
        throw new HttpError("FB auth response invalid", 999);
    }

    const loginPayload = {
        fbId: fbResponse?.authResponse?.userID,
        fbToken: fbResponse?.authResponse?.accessToken,
    };

    return await login(loginPayload);
};

export const logout = async (): Promise<null> => await api.post("/auth/logout").then((response) => response?.data);

export interface ForgottenPasswordPayload {
    username: User["email"];
}
export const forgottenPassword = async (payload: ForgottenPasswordPayload) =>
    await api.post<User>("/auth/reset", payload).then((response) => response?.data);

export interface ResetPasswordPayload {
    token: string;
    password: string;
}
export const resetPassword = async (payload: ResetPasswordPayload) =>
    await api.post<User>("/signedAction", payload).then((response) => response?.data);

export interface ResendInvitationPayload {
    token: string;
}
export const resendInvitation = async (payload: ResendInvitationPayload) =>
    await api.post<User>("/auth/reset", payload).then((response) => response?.data);

export interface UpdateMePayload extends User {
    avatarFile: RcFile;
}
export const update = async (payload: UpdateMePayload) => {
    const formData = serialize(payload, { indices: true });

    return await api.put<User>("/me", formData).then((response) => response?.data);
};

export const updateRole = async (payload: UpdateRolePayload) => {
    return await api.post<User>("/me", payload).then((response) => response?.data);
};

export const me = async () => await api.get<User>("/me").then((response) => response?.data);
