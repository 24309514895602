/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 28 28">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M10.57 1.63c.533-.147 1.098.101 1.35.595a2.333 2.333 0 0 0 4.16 0 1.17 1.17 0 0 1 1.35-.594A12.8 12.8 0 0 1 22.738 4.6c.377.35.48.908.252 1.37a2.333 2.333 0 0 0 2.095 3.363h.032c.516-.007.975.325 1.13.817a12.8 12.8 0 0 1 .587 3.85c0 .884-.09 1.748-.26 2.584-.12.585-.663.986-1.257.927a2.333 2.333 0 0 0-2.017 3.825c.385.457.363 1.131-.051 1.562a12.83 12.83 0 0 1-5.58 3.403 1.167 1.167 0 0 1-1.445-.763 2.335 2.335 0 0 0-4.446 0 1.167 1.167 0 0 1-1.444.763 12.83 12.83 0 0 1-5.58-3.403 1.167 1.167 0 0 1-.052-1.561 2.333 2.333 0 0 0-2.017-3.825 1.167 1.167 0 0 1-1.257-.928c-.17-.836-.26-1.7-.26-2.584 0-1.34.205-2.633.588-3.85a1.17 1.17 0 0 1 1.13-.817h.032A2.333 2.333 0 0 0 5.01 5.97a1.17 1.17 0 0 1 .252-1.37 12.8 12.8 0 0 1 5.306-2.97M7.43 5.81a4.67 4.67 0 0 1-3.652 5.777 10.5 10.5 0 0 0-.209 3.625 4.668 4.668 0 0 1 3.527 6.7 10.5 10.5 0 0 0 2.97 1.827A4.66 4.66 0 0 1 14 21.583c1.654 0 3.105.86 3.934 2.156a10.5 10.5 0 0 0 2.97-1.828 4.668 4.668 0 0 1 3.527-6.7 10.6 10.6 0 0 0-.21-3.624 4.67 4.67 0 0 1-3.65-5.778 10.5 10.5 0 0 0-2.969-1.675A4.66 4.66 0 0 1 14 5.834a4.66 4.66 0 0 1-3.602-1.7A10.5 10.5 0 0 0 7.43 5.809M8.75 14a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0M14 11.083a2.917 2.917 0 1 0 0 5.834 2.917 2.917 0 0 0 0-5.834"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgGear: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-gear", props.className)} />
));

export default SvgGear;
