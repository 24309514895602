/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18">
        <path
            fill="currentColor"
            d="M3 17.25a.75.75 0 0 1-.745-.663L2.25 16.5V8.746a3.4 3.4 0 0 1-.45-.197l-.161-.091-.525-.315A.75.75 0 0 1 .75 7.5v-3a.75.75 0 0 1 .75-.75h.75V1.5a.75.75 0 0 1 .663-.745L3 .75h12a.75.75 0 0 1 .745.663l.005.087v2.25h.75a.75.75 0 0 1 .745.663l.005.087v3a.75.75 0 0 1-.364.643l-.525.315q-.294.175-.61.288L15.75 16.5a.75.75 0 0 1-.662.745L15 17.25zm9.75-8.875-.139.083a3.38 3.38 0 0 1-3.31.091l-.162-.091L9 8.374l-.139.084a3.38 3.38 0 0 1-3.31.091l-.162-.091-.139-.084-.139.084a3.4 3.4 0 0 1-1.36.46L3.75 15.75h2.625V12a.75.75 0 0 1 .663-.745l.087-.005h3.75a.75.75 0 0 1 .75.75v3.75h2.625V8.918a3.4 3.4 0 0 1-1.361-.46zm-2.625 4.375h-2.25v3h2.25zm4.862-7.5H2.25v1.826l.16.096a1.88 1.88 0 0 0 1.809.066l.12-.066.525-.315a.75.75 0 0 1 .772 0l.524.315a1.88 1.88 0 0 0 1.93 0l.524-.315a.75.75 0 0 1 .772 0l.524.315a1.88 1.88 0 0 0 1.93 0l.524-.315a.75.75 0 0 1 .772 0l.524.315a1.88 1.88 0 0 0 1.93 0l.16-.097V5.25h-.763m-.737-3H3.75v1.5h10.5z"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgShop: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-shop", props.className)} />
));

export default SvgShop;
