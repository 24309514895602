/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 16 16">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6.333 3.436c0 .793-.597 1.436-1.333 1.436s-1.333-.643-1.333-1.436S4.264 2 5 2s1.333.643 1.333 1.436M5 9.538c.736 0 1.333-.643 1.333-1.436S5.736 6.667 5 6.667s-1.333.643-1.333 1.435c0 .793.597 1.436 1.333 1.436m0 4.667c.736 0 1.333-.643 1.333-1.436S5.736 11.333 5 11.333s-1.333.643-1.333 1.436.597 1.436 1.333 1.436M12.333 3.436c0 .793-.597 1.436-1.333 1.436s-1.333-.643-1.333-1.436S10.264 2 11 2s1.333.643 1.333 1.436M11 9.538c.736 0 1.333-.643 1.333-1.436S11.736 6.667 11 6.667s-1.333.643-1.333 1.435c0 .793.597 1.436 1.333 1.436m0 4.667c.736 0 1.333-.643 1.333-1.436s-.597-1.436-1.333-1.436-1.333.643-1.333 1.436.597 1.436 1.333 1.436"
            clipRule="evenodd"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgDrag: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-drag", props.className)} />
));

export default SvgDrag;
