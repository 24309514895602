/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 14 14">
        <g clipPath="url(#reload_svg__a)">
            <path
                fill="currentColor"
                d="M1.744 6.88a5.244 5.244 0 0 1 1.537-3.596 5.229 5.229 0 0 1 3.717-1.54 5.22 5.22 0 0 1 3.715 1.54q.226.228.424.48l-.92.718a.122.122 0 0 0 .046.216l2.685.657a.123.123 0 0 0 .15-.118l.013-2.764a.122.122 0 0 0-.197-.096l-.862.674A6.415 6.415 0 0 0 .583 6.875.12.12 0 0 0 .706 7h.916c.067 0 .12-.053.122-.12m11.55.12h-.916a.12.12 0 0 0-.123.12 5.227 5.227 0 0 1-1.537 3.596 5.23 5.23 0 0 1-3.716 1.54 5.23 5.23 0 0 1-4.14-2.02l.919-.718a.122.122 0 0 0-.046-.216l-2.684-.657a.123.123 0 0 0-.151.118l-.011 2.765c0 .103.118.16.197.097l.862-.674a6.415 6.415 0 0 0 11.469-3.826.12.12 0 0 0-.123-.125"
            />
        </g>
        <defs>
            <clipPath id="reload_svg__a">
                <path fill="currentColor" d="M0 0h14v14H0z" />
            </clipPath>
        </defs>
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgReload: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-reload", props.className)} />
));

export default SvgReload;
